<template>
  <div class="Login__wrapper">
    <section class="Login__form" name="login">
      <div class="Login__title">Login</div>

      <transition name="fade-in">
        <div class="Login__error-message" v-if="error">
          {{ error }}
        </div>
      </transition>
      <div class="Login__buttons-container">
        <button
          type="button"
          class="Login__button"
          :class="{ 'Login__button--active': isActiveTab === 'phone' }"
          @click="switchTab('phone')"
        >
          Phone
        </button>
        <button
          type="button"
          class="Login__button"
          :class="{ 'Login__button--active': isActiveTab === 'username' }"
          @click="switchTab('username')"
        >
          Username or email
        </button>
      </div>
      <div class="Grid-row" v-if="isActiveTab === 'username'">
        <input
          :class="{ 'is-danger': errors.has('login') }"
          @keyup.enter="$refs.password.focus()"
          class="field"
          data-vv-as="Username or E-mail"
          data-vv-delay="500"
          name="login"
          placeholder="Username or E-mail"
          ref="login"
          type="text"
          v-focus
          v-model="credentials.login"
          v-validate="'required'"
        />
        <span class="form-help is-danger" v-show="errors.has('login')">
          {{ errors.first("login") }}
        </span>
      </div>
      <div class="Grid-row" v-if="isActiveTab === 'phone'">
        <div class="Login__field-with-select">
          <country-select :selected.sync="selectedCountry"> </country-select>

          <div class="Login__country-code">+{{ phoneCode }}</div>

          <input
            v-focus
            type="text"
            name="phone_number"
            class="field"
            :class="{
              'is-danger': invalid && (errors.has('phone_number') || error),
            }"
            placeholder="Your Number"
            v-model="phone_number"
            v-validate="'required'"
            data-vv-delay="500"
            data-vv-as="Phone Number"
            ref="phone_number"
            inputmode="tel"
            autocomplete="tel"
            @keyup.enter.prevent="submit"
          />
        </div>
        <!-- <span
          v-show="invalid && (errors.has('phone_number') || error)"
          class="form-help is-danger"
        >
          {{ errors.first("phone_number") || error }}
        </span> -->
      </div>
      <div class="Grid-row">
        <div class="Login__field-with-icon">
          <input
            :class="{ 'is-danger': errors.has('password') }"
            @keyup.enter="submit"
            class="field"
            data-vv-as="Password"
            data-vv-delay="500"
            name="password"
            placeholder="Password"
            ref="password"
            type="password"
            v-model="credentials.password"
            v-validate="'required'"
          />
          <div
            @click="triggerPasswordVisibility"
            class="Login__password-visibility-trigger"
          >
            <transition mode="out-in" name="fade">
              <i
                class="icon-eye Login__password-visibility-trigger--on"
                key="on"
                v-if="showPassword"
              ></i>
              <i
                class="icon-ic_eye-cross Login__password-visibility-trigger--off"
                key="off"
                v-else
              ></i>
            </transition>
          </div>
        </div>
        <span class="form-help is-danger" v-show="errors.has('password')">
          {{ errors.first("password") }}
        </span>
      </div>
      <div class="Grid-row">
        <div class="form-actions">
          <button
            :class="{ processing: processing }"
            @click.stop.prevent="submit"
            class="submit-btn"
          >
            Log In
          </button>
        </div>
      </div>
    </section>

    <transition mode="out-in" name="appear-from-bottom">
      <div class="Login__processing" key="true" v-if="processing">
        <spinner />
      </div>
      <div class="Login__alternative" key="false" v-else>
        <a
          @click="goTo('password-recovery-method')"
          class="Login__forgot-password"
        >
          Forgot your password?
        </a>
        <div class="Login__new-user">
          <div class="Login__title">First-time User?</div>

          <a @click="goTo('signup-method')" class="submit-btn"> Sign Up </a>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import router from "@/router";
import CountrySelect from "@/components/common/country-select/country-select";
import Spinner from "@/components/common/spinner/spinner";
import { COUNTRIES } from "@/constants/countries.constant";

export default {
  components: {
    Spinner,
    CountrySelect,
  },
  data() {
    return {
      credentials: {
        login: "",
        password: "",
      },
      error: "",
      processing: false,
      showPassword: false,
      isActiveTab: "phone",
      selectedCountry: "NG",
      countries: [],
      invalid: false,
      phone_number: null,
    };
  },
  computed: {
    phoneCode() {
      return this.selectedCountry ? COUNTRIES[this.selectedCountry].code : null;
    },
  },

  watch: {
    selectedCountry(value) {
      localStorage.setItem("phone_code", value);
    },
  },
  methods: {
    switchTab(data) {
      this.isActiveTab = data;
      localStorage.setItem("login_tab", data);
      this.error = "";
      this.processing = false;
      this.invalid = false;
      this.credentials = {
        login: "",
        password: "",
      };
      this.phone_number = null;
    },
    submit() {
      this.$validator.validateAll().then((result) => {
        this.error = null;
        if (result) {
          this.processing = true;
          if (this.isActiveTab === "username") {
            this.$bus.$emit("verify-login-recaptcha", this.onReCAPTCHAVerified);
          }
          if (this.isActiveTab === "phone") {
            this.$bus.$emit(
              "verify-login-recaptcha",
              this.onReCAPTCHAVerifiedPhone
            );
          }
        }
      });
    },
    onReCAPTCHAVerified(token) {
      this.$store
        .dispatch("checkAndLogin", { token, ...this.credentials })
        .then(
          () => {
            setTimeout(() => {
              this.processing = false;
            }, 3000);
          },
          (error) => {
            this.error = error;
            this.processing = false;
          }
        );
    },
    goTo(name) {
      router.push({ name });
    },
    triggerPasswordVisibility() {
      this.showPassword = !this.showPassword;
      this.$refs.password.type = this.showPassword ? "text" : "password";
    },
    onReCAPTCHAVerifiedPhone(token) {
      this.invalid = false;
      this.$store
        .dispatch("checkPhoneNumber", {
          phone_number: this.phone_number,
          country_code: this.selectedCountry,
        })
        .then((valid_number) => {
          if (valid_number) {
            this.credentials.login = valid_number;
            this.$store
              .dispatch("checkAndLogin", { token, ...this.credentials })
              .then(
                () => {
                  setTimeout(() => {
                    this.processing = false;
                  }, 3000);
                },
                (error) => {
                  this.error = error;
                  this.processing = false;
                }
              );
          } else {
            this.error = "Phone Number is not valid.";
            this.invalid = true;
            this.processing = false;
          }
        });
    },
  },
  mounted() {
    if (localStorage.getItem("phone_code")) {
      this.selectedCountry = localStorage.getItem("phone_code");
    }
    if (localStorage.getItem("login_tab")) {
      this.isActiveTab = localStorage.getItem("login_tab");
    }
  },
};
</script>

<style scoped lang="scss">
@import "style.scss";
</style>
